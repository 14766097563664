import React, {useState} from 'react'

function setAge() {
    let currentDate = new Date()
    let currentYear = currentDate.getFullYear()
    let currentMonth = currentDate.getMonth() + 1
    let currentDay = currentDate.getDate()
    let baseAge = currentYear - 1988
    
    if(currentMonth < 4) {
        baseAge -= 1
    } else if(currentMonth == 4 && currentDay < 23) {
            baseAge -= 1
        } 
    return baseAge
}

let age = setAge()

function Home() {
   
    return (
        <>
            <main>
                {/*Card Images*/}
                <div className='card-images'>
                    <div className='col'>
                        <img className='card-img' src='/images/1.jpg' ></img>
                    </div>
                    <div className='col'>
                        <img className='card-img'src='/images/2.jpg'></img>
                    </div>
                    <div className='col'>
                        <img className='card-img'src='/images/3.jpg'></img>
                    </div>
                </div>
                {/*Profile*/}
                <div className='profile-div'>
                    <div className='profile-text'>
                        <p className='profile'>Hey, I am Emma Jane Charlotte Ferens. This is my own line of products.</p>
                        <p className='profile'>I live in Mt Albert, with my two flatmates. I am very independent. I work as a tutor at <a href='https://www.circability.org'>Circability</a>. A social circus that offers people of all ages and abilities classes, workshops and opertunities to perform, with a primary focus on inclusion programs for people with disabilities. I am a {`${age}`} year old woman with Down Syndrome.</p>
                        <p className='profile'>I love to do a lot of colouring to keep me occupied, because I enjoy thinking of what colours go together and it helps me to be mindful, and remember what my Dad said, "to stay in the present moment". I have used my colourings to make cards for my friends and family and they loved them so much that I decided to start a business called<img className='bwlogo' alt='Emmaline' src='/images/Emmalinebw.png'></img>. My line is gift cards and I hope to expand into other product lines using my colourings. I also love writing poetry so I incorporated this into my cards.</p>
                        <p className='profile'>I hope you will enjoy them.</p>
                    </div>   
                     <img className='profile-img' alt='Emma Ferens' src='/images/profilePic.jpg'></img>                                            
                </div>
            </main>
        </>
    )
}


export default Home