import React from 'react'

const Nav = (props) => {
    return(
    <div className='nav-container'>
        <button className='card-button' onClick={props.cards} type="button">
            Shop
        </button>
        <button className='home-button' onClick={props.home} type='button'>
            Home
        </button>
    </div>
    )
}

export default Nav