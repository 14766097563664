import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import { post } from 'superagent'

function Contact(props) {
    
    const [sent, setSent] = useState(false)
    const [inputValues, SetInputValues] = useState({
        firstName: '', lastName: '', email: '', message: '', street: '', suburb: '', city: '', country: '', postcode: '',
    })
    const [valid, setValid] = useState(false)
    
    const handleChange = event => {
        const { name, value } = event.target
        SetInputValues({ ...inputValues, [name]: value })
    }

    const validateEmail = event => {
        event.preventDefault()
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        setValid(re.test(inputValues.email))
        console.log(valid)
    }

    const handleClick = event => {
        event.preventDefault()
        setSent(true)
        
        let name = inputValues.firstName + ' ' + inputValues.lastName
        let email = inputValues.email
        let message = inputValues.message
        let address = inputValues.street + '/n' + inputValues.suburb + '/n' + inputValues.city + '/n' + inputValues.country + '/n' + inputValues.postcode
        let tag = props.state.cart.tag
        let blank = props.state.cart.blank
        let poem = props.state.cart.poem
        let total = props.state.total
        let post = props.state.post

        var templateParams = {
            name: name,
            email: email,
            message: message,
            address: address,
            tag: tag,
            blank: blank,
            poem: poem,
            total: total,
            post: post,
        };
        
        emailjs.send('default_service', 'template_q5z3pkq', templateParams, 'user_Zf2pkHv28X6ZJ5OWbpWqp')
            .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
            console.log('FAILED...', error);
            });
    }

    return (
        <>
        {/*Contact Form*/}
            <div className='contact-container'>
                <p className='contact-title'>CONTACT ME TO ORDER CARDS </p>
                <div className='contact-div'>
                    <div className='input-row'>
                        <p className='bold'>First Name</p>
                        <textarea className='input-col' rows='1' name='firstName' onChange={handleChange}></textarea>
                    </div>
                    <div className='input-row'>
                        <p className='bold'>Last Name</p>
                        <textarea className='input-col' rows='1' name='lastName' onChange={handleChange}></textarea>
                    </div>
                    <div className='input-row'>
                        <p className='bold'>Email</p>
                        <textarea className='input-col' rows='1' name='email' onChange={handleChange} onBlur={validateEmail}></textarea>
                        {!valid ? <p className='validation-text'>Please enter a valid email</p> : <p className='validation-text'>Thank You!</p> }
                    </div>
                    <div className='address'>
                        <div className='input-row'>
                            <p className='bold'>Street Address</p>
                            <textarea className='input-col' rows='1' name='street' onChange={handleChange}></textarea>
                        </div>
                        <div className='input-row'>
                            <p className='bold'>Suburb</p>
                            <textarea className='input-col' rows='1' name='suburb' onChange={handleChange}></textarea>
                        </div>
                        <div className='input-row'>
                            <p className='bold'>City</p>
                            <textarea className='input-col' rows='1' name='city' onChange={handleChange}></textarea>
                        </div>
                        <div className='input-row'>
                            <p className='bold'>Country</p>
                            <textarea className='input-col' rows='1' name='country' onChange={handleChange}></textarea>
                        </div>
                        <div className='input-row'>
                            <p className='bold'>Post Code</p>
                            <textarea className='input-col' rows='1' name='postcode' onChange={handleChange}></textarea>
                        </div>
                    </div>
                    <div id='message-row'className='input-row'>
                        <p id='message-p' className='bold'>Message</p>
                        <textarea id='message' className='input-col' rows='10' name='message' onChange={handleChange}></textarea>
                    </div>
                </div>
                {/*Send Button*/}
                <div className='button-div'>
                    <div className='button-buffer'></div>
                    <button className='send-button' type='button' name='send' onClick={handleClick}>Send</button>
                </div>
                {/*Sent Message*/}
                {
                    sent && 
                    <div className='sent-div'>
                        <p className='sent-message'>Thank You,<br/><br/>Your message has been sent!<br/>We'll get back to you as soon as possible.<br/>Have a great day!</p>
                    </div>
                }
                {/*Purple Logo*/}
                <img className='purple-logo' src='/images/Emmaline_logo_purples.png' ></img>
            </div>
        </>

    )
} 

export default Contact









