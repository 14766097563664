import React, { useState } from 'react'
import Shop from './Shop'
import Cart from './Cart'
//start of cardViewer component
function CardViewer(props) {

  const [hovering, setHovering] = useState(false)
  
  return(
    <>
      <div className='card-image-container'> 
        {/*<div className='back-arrow' onClick={props.back}>
          <img className='back-arrow-img' src='/images/arrowLeft.png' /> 
        </div> */}
        {/* span for mouse over text */}
        <span title='Click Next' className='card-image-span'>
          <img 
          // show image that corresponds to cardNumber state
          className='card-image' src={`/images/${ props.state.cardNumber }.jpg`} 
          //implement handleClickNext function in card-image
          onClick={props.next} 
          // use onMouseEnter() and onMouseLeave() to set hovering state
          onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}
          /> 
          {/* check if mouse is hovering over card-image and show hover-text if it is */}
          {hovering && <p className='hover-text'>View Next Pack</p>}
        </span>    
        {/*<div className='next-arrow' onClick={props.next}>
          <img className='next-arrow-img' src='/images/arrowRight.png' /> 
        </div>*/}  
        {/* pass Shop component cardNumber state as prop */}
        <Shop state={props.state} handleChange={props.handleChange} handleBlur={props.handleBlur} handleClick={props.handleClick} addPostage={props.addPostage} removePostage={props.removePostage} setPost={props.setPost} />
        {props.state.total !== 0 && <Cart state={props.state} addPostage={props.addPostage} removePostage={props.removePostage} setPost={props.setPost} />}
      </div>
    </>
  )
  
}


export default CardViewer