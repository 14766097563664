import React from 'react'
import Home from './Home'
import Header from './Header'
import Contact from './Contact'
import CardViewer from './CardViewer'


//start of App component
class App extends React.Component  {
  constructor(props) {
    super(props)
    this.state ={
      view: 'cardviewer',
      tagQty: 0,
      packAQty: 0,
      packBQty: 0,
      packCQty: 0,
      packDQty: 0,
      calendarQty: 0,
      calendarPackQty: 0,
      tagCost: 10,
      blankCost: 20,
      calendarCost: 30,
      calendarPackCost: 50,
      postCost: 3.5,
      cart: {
        tag: 0,
        packA: 0,
        packB: 0,
        packC: 0,
        packD: 0,
        calendar: 0,
        calendarPack: 0,
      },
      total: 0,
      cardNumber: 4,
      totalCards: 10,
      post: false,
    }
  }
  //functions to set view state that cn be passed to props 
  showCards = () => {
    this.setState({view: 'cards'})
  }

  showHome = () => {
    this.setState({view: 'home'})
  }

  handleClickNext = event => {
    event.preventDefault()
    //change card_number state
    if (this.state.cardNumber < this.state.totalCards) {
      this.setState({cardNumber: this.state.cardNumber + 1})
    } else {
      this.setState({cardNumber: 4})
    }
  }

  handleClickBack = event => {
    event.preventDefault()
    //change cardNumber state
    if (this.state.cardNumber > 4) {
      this.setState({cardNumber: this.state.cardNumber -1})
    } else {
      this.setState({cardNumber: this.state.totalCards})
    } 
  }

  handleChange = (event) => {
    let currentCard = this.state.cardNumber
    switch (currentCard) {
        case 4:
            this.setState({tagQty: event.target.value})
            break
        case 5:
            this.setState({packAQty: event.target.value})
            break
        case 6:
            this.setState({packBQty: event.target.value})
            break
        case 7:
            this.setState({packCQty: event.target.value})
            break
        case 8:
            this.setState({packDQty: event.target.value})
            break
        case 9:
            this.setState({calendarQty: event.target.value})
            break
        case 10:
            this.setState({calendarPackQty: event.target.value})
            break

    } 
}

  handleBlur = event => {
    event.preventDefault()
    this.setState({
      cart:{
        tag: Number(this.state.cart.tag) + Number(this.state.tagQty),
        packA: Number(this.state.cart.packA) + Number(this.state.packAQty),
        packB: Number(this.state.cart.packB) + Number(this.state.packBQty),
        packC: Number(this.state.cart.packC) + Number(this.state.packCQty),
        packD: Number(this.state.cart.packD) + Number(this.state.packDQty),
        calendar: Number(this.state.cart.calendar) + Number(this.state.calendarQty),
        calendarPack: Number(this.state.cart.calendarPack) + Number(this.state.calendarPackQty),
      }
    })
    this.setState({tagQty: 0})
    this.setState({PackAQty: 0})
    this.setState({PackBQty: 0})
    this.setState({PackCQty: 0})
    this.setState({PackDQty: 0})
    this.setState({calendarQty: 0})
    this.setState({calendarPackQty: 0})
    document.getElementById('qty').value = 0
  }

  addPostage = () => {
    this.setState({total: this.state.total + 3.5})
  }

  removePostage = () => {
    this.setState({total: this.state.total - 3.5})
  }

  setPost = (value) => {
    this.setState({post: value})
  }

  calcTotal = () =>{
    let total = Number(this.state.cart.tag) * this.state.tagCost + Number(this.state.cart.packA) * this.state.blankCost + Number(this.state.cart.packB) * this.state.blankCost + Number(this.state.cart.packC) * this.state.blankCost + Number(this.state.cart.packD) * this.state.blankCost + Number(this.state.cart.calendar) * this.state.calendarCost + Number(this.state.cart.calendarPack) * this.state.calendarPackCost
    return total
  }

  handleClick = event => {
    event.preventDefault()
    
    if (this.state.post) {
      this.setState({total: this.calcTotal() + postCost})
    } else {
        this.setState({total: this.calcTotal()})  
      }
  } 
    
  // render app
  render(){
    return (
      <>
        {/* pass view state functions into Header components props */}
        <Header cards={this.showCards} home={this.showHome}/>
        {/* turnery operator to select view, change to if statement if more views added */}
        {
        this.state.view === 'home' ? 
        <Home /> : <CardViewer
                      next={this.handleClickNext} back={this.handleClickBack} handleChange={this.handleChange} handleBlur={this.handleBlur} handleClick={this.handleClick} addPostage={this.addPostage} removePostage={this.removePostage} setPost={this.setPost} state={this.state} 
                  />
        }
        <Contact state={this.state}/>
      </>
    )
  }
}
//export App component
export default App
