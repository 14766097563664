import React, { useState } from 'react'
import Cart from './Cart'

const Shop = (props) => {
    let currentCard = props.state.cardNumber

    const renderSwitch = () => {
        let currentCard = props.state.cardNumber
        switch (currentCard) {
            case 4:
                return <p className='pack-type'>Pack A: Six Greeting Cards ${props.state.blankCost}</p>
            case 5: 
                return <p className='pack-type'>Pack B: Six Greeting Cards ${props.state.blankCost}</p> 
            case 6:
                return <p className='pack-type'>Pack A: Six Greeting Cards ${props.state.blankCost}</p>
            case 7: 
                return <p className='pack-type'>Pack B: Six Greeting Cards ${props.state.blankCost}</p> 
            case 8:
                return <p className='pack-type'>Lucky Dip Gift Tags Pack: Six Gift Tags ${props.state.tagCost}</p>
            case 9: 
                return <p className='pack-type'>Calendar ${props.state.calendarCost}</p>
            case 10:
                return <p className='pack-type'>Two Calendar Pack ${props.state.calendarPackCost}</p>
                
    }

}
    return (
        <>
            <div className='shop-div'>
                {renderSwitch(currentCard)}
                <div className='add-div'>
                    <input id='qty' className='qty' name='qty' type='text' placeholder='Qty' onChange={props.handleChange} onBlur={props.handleBlur}></input>
                    <button className='add-cart' onClick={props.handleClick}>Add To Cart</button>
                </div>
            </div>
        </>
    )
}

export default Shop