import React from 'react'

const Cart = props => {
   
    const handleCheckChange = event => {
        let target = event.target.id
        let box = document.getElementById(target)
        let boxes = document.getElementsByClassName('delivery-check')
        
        if (box.id === boxes[0].id && box.checked) {
            boxes[1].checked = false
        } else if (box.id === boxes[1].id && box.checked) {
            boxes[0].checked = false
        }
        if (boxes[1].checked && props.state.total % 2 == 0) {
            props.addPostage()
            props.setPost(true)
        } else {
            props.setPost(false)
        }
        if (boxes[0].checked && props.state.total % 2 != 0) {
            props.removePostage()
            props.setPost(false)
        }

            
    } 

    return (
        <>
            <div className='cart-div'>
                <h2 className='cart-title'>Cart</h2>
                <div className='post-pickup'>
                    <span className='checkbox-span'>
                        <label id='pickup-txt' className='cart-txt'>
                            <input id='pickup' type='checkbox' className='delivery-check' onChange={handleCheckChange}/>Pick-up cards
                        </label>
                    </span>
                    <span className='checkbox-span'>
                        <label id='post-txt' className='cart-txt'>
                        <input id='post' type='checkbox' className='delivery-check' onChange={handleCheckChange}/>Add postage ($3.50)
                        </label>
                    </span>
                </div>
                <div className='cart-body'>
                    <div className='cart-values'>
                        <p className='items'>Gift Tags: {props.state.cart.tag}</p>
                        <p className='items'>Greeting Cards Pack A: {props.state.cart.packA}</p>
                        <p className='items'>Greeting Cards Pack B: {props.state.cart.packB}</p>
                        <p className='items'>Greeting Cards Pack C: {props.state.cart.packC}</p>
                        <p className='items'>Greeting Cards Pack D: {props.state.cart.packD}</p>
                        <p className='items'>Calender: {props.state.cart.calendar}</p>
                        <p className='items'>Two Calender Pack: {props.state.cart.calendarPack}</p>
                        <p className='items'>Total: ${props.state.total}</p>
                    </div>
                    <div className='cart-message'>
                        <p id='cart-message-p'>Anything you add to the cart will be automatically added to an email to Emma for you. Please fill out your contact details in the form below and click send. Emma will get in touch, as soon as she can, with payment instructions.</p>
                        <p>Thank You For Your Support!</p>
                    </div>
                </div>
            </div>       
        </>
    )
}

export default Cart

